// Copyright (C) Omics Data Automation, Inc. - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import { base64Decode } from '@/common/shared.js'

// parseCSV is from: https://stackoverflow.com/questions/1293147/example-javascript-code-to-parse-csv-data
const parseCSV = (str) => {
  const arr = []
  let quote = false  // 'true' means we're inside a quoted field

  // Iterate over each character, keep track of current row and column (of the returned array)
  for (let row = 0, col = 0, c = 0; c < str.length; c++) {
    const cc = str[c], nc = str[c + 1]        // Current character, next character
    arr[row] = arr[row] || []             // Create a new row if necessary
    arr[row][col] = arr[row][col] || ''   // Create a new column (start with empty string) if necessary

    // If the current character is a quotation mark, and we're inside a
    // quoted field, and the next character is also a quotation mark,
    // add a quotation mark to the current column and skip the next character
    if (cc == '"' && quote && nc == '"') { arr[row][col] += cc; ++c; continue }

    // If it's just one quotation mark, begin/end quoted field
    if (cc == '"') { quote = !quote; continue }

    // If it's a comma and we're not in a quoted field, move on to the next column
    if (cc == ',' && !quote) { ++col; continue }

    // If it's a newline (CRLF) and we're not in a quoted field, skip the next character
    // and move on to the next row and move to column 0 of that new row
    if (cc == '\r' && nc == '\n' && !quote) { ++row; col = 0; ++c; continue }

    // If it's a newline (LF or CR) and we're not in a quoted field,
    // move on to the next row and move to column 0 of that new row
    if (cc == '\n' && !quote) { ++row; col = 0; continue }
    if (cc == '\r' && !quote) { ++row; col = 0; continue }

    // Otherwise, append the current character to the current column
    arr[row][col] += cc
  }

  return arr
}

const parseTSV = (str) => {
  const arr = []
  str.trim().split('\n').forEach((line) => {
    arr.push(line.split('\t'))
  })

  return arr
}

export const parseTableData = (props) => {
  if (props.payload.type !== 'base64-csv' && props.payload.type !== 'base64-tsv') {
    return { tableDataRows: null, tableHeaderColumns: null }
  }

  const data = base64Decode(props.payload.data)
  if (!data) { return { tableDataRows: null, tableHeaderColumns: null } }

  const arr = props.payload.type === 'base64-csv' ? parseCSV(data) : parseTSV(data)

  const tableDataRows = arr.splice(1)
  const tableHeaderColumns = arr[0]

  return {
    tableDataRows,
    tableHeaderColumns,
  }
}

const rowToCsv = (row) => {
  const columns = []
  row.forEach((col) => {
    const field = (!col || /[,a-z_]/i.test(col)) ? `"${col}"` : col
    columns.push(field)
  })
  return columns.join(',')
}

export const tableToCsv = (props, { tableDataRows, tableHeaderColumns }) => {
  if (props.payload.type === 'base64-csv') {
    return base64Decode(props.payload.data)
  }

  const lines = [rowToCsv(tableHeaderColumns)]
  tableDataRows.forEach((row) => lines.push(rowToCsv(row)))
  return lines.join('\n') + '\n'
}

export const tableToTsv = (props, { tableDataRows, tableHeaderColumns }) => {
  if (props.payload.type === 'base64-tsv') {
    return base64Decode(props.payload.data)
  }

  const lines = [tableHeaderColumns.join('\t')]
  tableDataRows.forEach((row) => lines.push(row.join('\t')))
  return lines.join('\n') + '\n'
}
